<template>
  <div style="padding-left: 1rem;">
    <span class="col2_head"><router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link> {{ $route.name }}<div class="subHeadTitle">{{ $route.name }}</div></span>
    <v-table v-if="PERMISIONS.currency" :key="tableKey" :Data="tableData" />
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center;">
        <span style="font-size: 4rem;">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
  import PermisionsMixin from '@/mixins/allPermMixin.js'
  import { CURRENCY } from '@/api.js'
  import Table from '@/components/Table'

  export default {
    $Title() {
      return `ConectX - ${this.$route.name}`
    },
    mixins: [PermisionsMixin],
    data() {
      return {
        tableKey: 0,
        tableData: {
          options: {
            spread: true,
            minimizeHead: true
          },
          head: [
            {title:'NR.',minimize:2,sort:true,fixed:{left:true}},
            {title: 'Data'},
            {title: 'Codul valutar'},
            {title: ''},
            {title: 'Curs valutar'}
          ],
          body: []
        }
      }
    },
    methods: {
      prepareData(data) {
        if(!Array.isArray(data)) return

        const eur = data.findIndex(e => e.currencyCode == 'EUR')
        if(~eur) {
          data.splice(0, 1, data[eur])
          data.splice(eur, 1)
        }

        const prepared = []
        data.forEach((e, i) => {
          prepared.push([
            {val:i+1, border:'right'}, 
            this.toDateAndTime(e.currencyRateDate), 
            e.currencyCode, 
            '',
            e.rate
          ])
        })
        this.tableData.body = prepared.length ? 
        prepared : 
        [false]
        this.tableKey++
      },
      syncData() {
        const err = msg => this.$toastr.e(msg || 'A apărut o eroare la obținerea datelor.')
        CURRENCY().then(data => {
          if(!Array.isArray(data?.data?.result) || !Array.isArray(data.data.result[0])) {
            err()
            return
          }
          this.prepareData(data.data.result[0])
        }).catch(msg => err(msg))
      }
    },
    created() {
      this.initAllPermisions()

      if(this.PERMISIONS.currency) this.syncData()
    },
    components: {
      'v-table': Table
    }
  }
</script>